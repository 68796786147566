body {
  background: #FAFAFA;
}
.container.mod-3 {
  width: 1280px;
  height: 100vh;
  margin-top:-2em;
  padding: 6em;
  background: #FAFAFA;
}

.card {
  color: #202020;
  height:300px;
}

.card .mod-2 > h2 {
  text-align: left;
  padding-left: 3em;
}

img {
  height: 350px;
  padding-left: 2em;
}

img.emojipop {
  height: 300px;
  margin-right: 2em;
}
p {
  font-family: 'Montserrat', sans-serif;
  width:100%;
  font-size: 16px;
}

.icon-container {
  width:100%;
  position:relative;
  height: 200px;
}

.icon-tile {
  width: 100px;
  display: inline-block;
  position: relative;
  float:left;
}
.icon-tile span {
  margin: 0 auto;
}

.icon-tile p {
  text-align: center;
  width: 100px;
}

.description {
  margin-top: 2em;
  width: 50%;
}

.description3 {
  margin-top: 10em;
  width: 50%;
}
