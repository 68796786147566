.App {
  background: #FAFAFA;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #FEFEFE;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #202020;
}

a:visited {
  color: #202020;
}


footer {
  margin-top:-8em;
  margin-left: 8em;
}
