.mod-2.container {
  width: 1280px;
  height: 100vh;
  background:#FAFAFA;
  margin-top:-2em;
  color: #202020;
}

img.me {
  margin-top: 10em;
  margin-left: 6em;
}

.float-right {
  float: right;
}

p.description {
  float: left;
  margin-top : 17em;
  margin-left: 8em;
}
