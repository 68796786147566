
nav {
  height: 60px;
  width:100%;
  top:0;
  text-align:center;
  background: #FAFAFA;
}
img.logo {
  height: 100px;
  margin-right: 0em;
  margin: 0 0 -1.5em -4em;
}
li {
  width:250px;
  height:60px;
  display:inline-block;
  position:relative;

}
nav > ul > li > a {
  font-size: 30px;
  text-decoration: none;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700;
}

nav > ul > li > a:active {
  color:#404040;
  font-size: 28px;
}


@media only screen and (max-width: 677px) {
  nav.navbar {background: white}
   ul.nav {
     top:0;
     background:white;
     z-index: 1000;
  }
  nav {z-index: 1000}
  img {
    z-index: -100;
  }
}
