.wrapper {

}


label {
    font-size: 24px;
    margin-right: 10px;
}

input, textarea {
  opacity:0.5;
  font-size:24px;
  margin-bottom: 1em;
  border: 1px solid #DEDEDE;
}


form{
  position:relative;
  margin-top: 12em;
  margin-left: 6em;
  width: 10%;
  margin-left: 22em;
}


input[type=submit] {
  width : 100%;
  background-color: #202020;
  height: 44px;
  color: white;
  border-radius: 4px;
}

.contact-container {
  margin: 18em 18em;
}
